import { Injectable, Inject, PLATFORM_ID, InjectionToken, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../auth/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { IUserInfo } from '@core/models/auth/IDecodedToken.interface';
import { environment } from '@envs/environment';
import { LoginStore } from '@core/store/login.store';
import { catchError, tap, throwError } from 'rxjs';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  defaultLang = 'es';
  localStorageService = inject(LocalStorageService);
  loginStore = inject(LoginStore);
  loginService = inject(LoginService);
  private httpClient = inject(HttpClient);

  constructor(
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const savedLang = this.localStorageService.getLang();
      if (savedLang) {
        this.defaultLang = savedLang;
      }
      this.translateService.setDefaultLang(this.defaultLang);
      this.translateService.use(this.defaultLang);
    }
  }

  changeLang(lang: string) {
    console.log('lang', lang);
    this.translateService.use(lang);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.setLang(lang);
    }
  }

  setLanguage(language: string) {

    console.log('language', language);
    this.httpClient.patch<IUserInfo>(environment.API_BASE_URL + 'users/update', { language }).pipe(
      tap((res) => {
        console.log('res', res);
      }),
      catchError((err) => {
        console.error('Error:', err);
        return throwError(() => err);
      })
    ).subscribe((res) => {
      this.changeLang(res.language.split('_')[0].toLocaleLowerCase());
      this.loginStore.setUserLanguage(language);
      this.loginService.refreshToken().subscribe(
        (res) => {
          this.localStorageService.setTokens(res.token, res.refresh_token);
        }
      );
    });
  }
}
